<script>
  import toast, { Toaster } from "svelte-french-toast";

  import Main from "./Main.svelte";
  import NavBar from "./NavBar.svelte";
  import {
    alerts,
    formatUploadJson,
    isEmbedded,
    sendMessageToParent,
  } from "./store";
  import { onMount } from "svelte";

  function initApp() {
    let inIframe = false;
    try {
      inIframe = window.self !== window.top;
      //inIframe = true //dev porposes
    } catch (e) {
      inIframe = true; // Si hay un error, probablemente estamos en un iframe
    }

    if (inIframe) {
      sendMessageToParent({ type: "command", body: "ready" });
      //isSaveButtonDisabled.set(false);
      isEmbedded.set(true);
    } else {
      //isSaveButtonDisabled.set(true);
      //TEST DATA

      /*let defaultJson = formatUploadJson([
        {
          name: "HOS",
          alertid: "9874d02b-89bb-412b-81c6-167615f775aa",
          severity: 0,
          description:
            "Alerta cuando se excede el tiempo máximo de conducción permitido (HOS).",
          message: "Exceso de horas de conducción, {unidad}",
          chat_message:
            "Conductor, ha excedido el tiempo de conducción permitido. Por favor, haga una pausa y notifique su situación.",
          report_tels: [],
          disabled: false,
          frequency_max: 1,
          freq_counter_min: -1,
          freq_discounter_min: -1,
          protocol: 0,
          icon: "traffic",
          event_alert: {
            count: 1,
            counter: [],
            discounter: [],
          },
          cron_alert: {
            count: 1,
            counter: [
              {
                hos: {
                  type: "equal",
                  val: false,
                },
              },
            ],
            discounter: [
              {
                hos: {
                  type: "equal",
                  val: false,
                },
              },
            ],
          },
          emails: ["irvinvp@gmail.com"],
        },

        {
          name: "test hos",
          alertid: "a073f4b4-4670-4d22-bff4-85a7b8b4c350",
          severity: 0,
          description: "",
          chat_message: "",
          frequency_max: 0,
          freq_counter_min: 0,
          freq_discounter_min: 0,
          protocol: 0,
          message: "",
          report_tels: [],
          emails: [],
          disabled: false,
          icon: "warning",
          event_alert: {
            count: 1,
            counter: [
              {
                hos: {
                  type: "equal",
                  val: true,
                },
              },
            ],
            discounter: [
              {
                hos: {
                  type: "equal",
                  val: false,
                },
              },
            ],
          },
          cron_alert: {
            count: 1,
            counter: [
              {
                hos: {
                  type: "equal",
                  val: true,
                },
              },
            ],
            discounter: [
              {
                ingnition: {
                  type: "equal",
                  val: 0,
                },
              },
            ],
          },
        },
      ]);
      alerts.set(defaultJson);*/

      isEmbedded.set(false);
    }
  }

  function setWindowEventListeners() {
    console.log("Setting window event listeners");
    window.addEventListener("message", (event) => {
      console.log("data recieved from parent:", event.data);
      let recievedJson = event.data;
      if (recievedJson && recievedJson.type === "alerts") {
        const formattedJson = formatUploadJson(recievedJson.body); // Formatear JSON
        console.log("formattedJson:", formattedJson);
        //alerts.update((currentAlerts) => [...currentAlerts, ...formattedJson]);
        //borrar alertas e insertar las nuevas
        alerts.set(formattedJson);
        toast.success("Archivo JSON cargado con éxito.");
      }
    });
  }

  initApp();
  setWindowEventListeners();

  onMount(() => {});
</script>

<main>
  <Toaster />
  {#if !$isEmbedded}
    <NavBar></NavBar>
  {/if}

  <Main></Main>
  <!--<div class="d-flex justify-content-center mt-5">v. {version}</div>-->
</main>

<style>
</style>
