import { writable } from 'svelte/store';
import { scale } from 'svelte/transition';
import { get } from 'svelte/store';
import toast from 'svelte-french-toast';

export const alerts = writable([]);
export const isSimpleMode = writable(false);
export const jsonEditable = writable(false);
export const lastJsonIsValid = writable(true);
export const alertTrend = writable(1);
export const extraFieldsMap = new Map();
export const version = "1.0.2";



export var currFormStep = writable(0);
/*export var formTitles = [
  "Identificación","Test1","Test2","Test3","test4", "test5", "test6"
];*/
export var isSaveButtonDisabled = writable(true);
export var isEmbedded = writable(false);

export var evenCounter = writable([]);
export var evenDiscounter = writable([]);
export var cronCounter = writable([]);
export var cronDiscounter = writable([]);

export var isEditMode = writable(false);
export var orgJson = writable({});
export var isDataModified = writable(false);

export var isTutorialMode = writable(true);

export var formTitles = [
  {
    title: "Identificación",
    description: "Datos básicos"
  },
  {
    title: "Contacto",
    description: "Datos de contacto"
  },
  {
    title: "Alertas Eventos",
    description: "Generadas por eventos"
  },
  {
    title: "Discounter Eventos",
    description: "Generadas por eventos"
  },
  {
    title: "Alertas CRON",
    description: "Verificadas por tiempo"
  },
  {
    title: "Discounter CRON",
    description: "Verificadas por tiempo"
  }

];

export const operators = [
  { name: 'Igual', value: 'equal' },
  { name: 'Mayor', value: 'major' },
  { name: 'Menor', value: 'minor' },
  { name: 'Diferente', value: 'noequal' },
]

export const geoOperators = [
  { name: 'Igual', value: 'equal' },
  { name: 'Diferente', value: 'noequal' },
]

export const hosValues = [
  { name: 'Excedido', value: true },
  { name: 'No Excedido', value: false },
]

export const geoValues = [
  { name: 'Segura', value: 'secure' },
  { name: 'Insegura', value: 'nosecure' },
  { name: 'Fuera de geocerca', value: 'nogeo' },
]

export const ingnitionValues = [
  { name: 'Encendida', value: 1 },
  { name: 'Apagada', value: 0 },
]

export const manualValues = [
  { name: 'Activa', value: 1 },
  { name: 'Inactiva', value: 0 },
]

export const travelManualValues = [
  { name: "Solicitud de parada", value: 'travel_stop_query' },
  { name: "Solicitud de fin de ruta", value: 'travel_finish_query' },
  { name: "Solitud de SOS", value: 'travel_sos' },
]

export const geoDestValues = [
  { name: 'Llegada', value: 1 },
  { name: 'No llegada', value: 0 },
]

export const travelStopQueryValues = [
  { name: 'Activa', value: 'equal' },
  { name: 'Inactiva', value: 'noequal' },
]

export const travelFinishQueryValues = [
  { name: 'Activa', value: 'equal' },
  { name: 'Inactiva', value: 'noequal' },
]

export const travelSosQueryValues = [
  { name: 'Activa', value: 'equal' },
  { name: 'Inactiva', value: 'noequal' },
]

export const followValues = [
  { name: 'Iniciado', value: 1 },
  { name: 'No iniciado', value: 0 },
];

export var devicesTypes = writable([
  { name: 'FS', value: 'FS', checked: false },
  { name: 'TMCI', value: 'TMCI', checked: false },
  { name: 'IVG', value: 'IVG', checked: false },
  { name: 'GNX', value: 'GNX', checked: false },
  { name: 'Por defecto', value: 'default', checked: true }
]);

export const lastPosValues = {
  default: 0,
  FS: 0,
  TMCI: 0,
  IVG: 0
};

export const hdopValues = [
  { name: 'Malo', value: 'bad' },
  { name: 'Bueno', value: 'good' },
  { name: 'Excelente', value: 'excellent' },

]

export const isFormCompleted = writable(false);

// Nuevo store para almacenar los datos del formulario 1
export var formOneData = writable({
  alertId: "",
  alertName: "",
  alertDescription: "",
  alertDeviceTypes: [],
  alertFrequencyMax: 0,
  alertFreqCounterMin: 0,
  alertFreqDiscounterMin: 0,
  alertProtocol: 0,
  alertSeverity: 0,
  alertIcon: "warning",
  alertActive: true
});

export var formTwoData = writable({
  contactNums: [],
  contactEmails: [],
  alertMessage: "",
  alertChatMessage: ""
});

/*export const formThreeData = writable({
  conditionGroups: [
    {
      rows: [
        {
          selectedEventIndex: 0,
          operatorIndex: 0,
          selectedValue: "",
          selectedValueIndexes: get(devicesTypes).map(() => 0),
          deviceValues: get(devicesTypes).map(device => ({ ...device, inputValue: "" })),
        },
      ],
      conditions: [],
    },
  ],
  alertTrend: 1,
});*/

export const formThreeData = writable({
  alertTrend: 1,
  conditionGroups: [{
    rows: [
      {
        selectedEventIndex: 0,
        operatorIndex: 0,
        selectedValue: "",
        selectedValueIndexes: [0],
        deviceValues: [
          // { name: 'Por defecto', value: 'default', checked: true, inputValue: "41" },
        ],
      },
    ],
    conditions: [],
  },]
});


/*export const formFourData = writable({
  conditionGroups: [
    {
      rows: [
        {
          selectedEventIndex: 0,
          operatorIndex: 0,
          selectedValue: "",
          selectedValueIndexes: get(devicesTypes).map(() => 0),
          deviceValues: get(devicesTypes).map(device => ({ ...device, inputValue: "" })),
        },
      ],
      conditions: [],
    },
  ],
  alertTrend: 1,
});*/

export const formFourData = writable({
  conditionGroups: [],
  alertTrend: 1,
});

/*export const formFiveData = writable({
  conditionGroups: [
    {
      rows: [
        {
          selectedEventIndex: 0,
          operatorIndex: 0,
          selectedValue: "",
          selectedValueIndexes: get(devicesTypes).map(() => 0),
          deviceValues: get(devicesTypes).map(device => ({ ...device, inputValue: "" })),
        },
      ],
      conditions: [],
    },
  ],
  alertTrend: 1,
});*/

export const formFiveData = writable({
  conditionGroups: [],
  alertTrend: 1,
});

export const formSixData = writable({
  conditionGroups: [],
  alertTrend: 1,
});


// Lista de iconos de Material Icons
export const materialIcons = [
  { name: 'Home', icon: 'home' },
  { name: 'Menú', icon: 'menu' },
  { name: 'Buscar', icon: 'search' },
  { name: 'Configuraciones', icon: 'settings' },
  { name: 'Cuenta', icon: 'account_circle' },
  { name: 'Favorito', icon: 'favorite' },
  { name: 'Eliminar', icon: 'delete' },
  { name: 'Agregar', icon: 'add' },
  { name: 'Check', icon: 'check_circle' },
  { name: 'Error', icon: 'error' },
  { name: 'Información', icon: 'info' },
  { name: 'Advertencia', icon: 'warning' },
  { name: 'Ayuda', icon: 'help' },
  { name: 'Carrito', icon: 'shopping_cart' },
  { name: 'Me gusta', icon: 'thumb_up' },
  { name: 'No me gusta', icon: 'thumb_down' },
  { name: 'Editar', icon: 'edit' },
  { name: 'Visible', icon: 'visibility' },
  { name: 'No visible', icon: 'visibility_off' },
  { name: 'Candado', icon: 'lock' },
  { name: 'Candado abierto', icon: 'lock_open' },
  { name: 'Cerrar sesión', icon: 'logout' },
  { name: 'Iniciar sesión', icon: 'login' },
  { name: 'Enviar', icon: 'send' },
  { name: 'Descargar', icon: 'download' },
  { name: 'Subir', icon: 'upload' },
  { name: 'Nube', icon: 'cloud' },
  { name: 'Descargar de la nube', icon: 'cloud_download' },
  { name: 'Subir a la nube', icon: 'cloud_upload' },
  { name: 'Cámara', icon: 'camera_alt' },
  { name: 'Foto', icon: 'photo' },
  { name: 'Teléfono', icon: 'phone' },
  { name: 'Correo', icon: 'email' },
  { name: 'Mapa', icon: 'map' },
  { name: 'Ubicación encendida', icon: 'location_on' },
  { name: 'Ubicación apagada', icon: 'location_off' },
  { name: 'Direcciones', icon: 'directions' },
  { name: 'Coche', icon: 'directions_car' },
  { name: 'Bicicleta', icon: 'directions_bike' },
  { name: 'Caminar', icon: 'directions_walk' },
  { name: 'Autobús', icon: 'directions_bus' },
  { name: 'Notificaciones', icon: 'notifications' },
  { name: 'Notificaciones activas', icon: 'notifications_active' },
  { name: 'Notificaciones apagadas', icon: 'notifications_off' },
  { name: 'Brillo alto', icon: 'brightness_high' },
  { name: 'Brillo bajo', icon: 'brightness_low' },
  { name: 'Volumen alto', icon: 'volume_up' },
  { name: 'Volumen apagado', icon: 'volume_off' },
  { name: 'Volumen bajo', icon: 'volume_down' },
  { name: 'Reproducir', icon: 'play_arrow' },
  { name: 'Pausa', icon: 'pause' },
  { name: 'Parar', icon: 'stop' },
  { name: 'Siguiente', icon: 'skip_next' },
  { name: 'Anterior', icon: 'skip_previous' },
  { name: 'Avance rápido', icon: 'fast_forward' },
  { name: 'Rebobinar', icon: 'fast_rewind' },
  { name: 'Actualizar', icon: 'refresh' },
  { name: 'Sincronizar', icon: 'sync' },
  { name: 'Herramientas', icon: 'build' },
  { name: 'Seguridad', icon: 'security' },
  { name: 'Respaldo', icon: 'backup' },
  { name: 'Batería llena', icon: 'battery_full' },
  { name: 'Batería cargando', icon: 'battery_charging_full' },
  { name: 'Batería alerta', icon: 'battery_alert' },
  { name: 'Batería desconocida', icon: 'battery_unknown' },
  { name: 'Bluetooth', icon: 'bluetooth' },
  { name: 'Wi-Fi', icon: 'wifi' },
  { name: 'Modo avión activo', icon: 'airplanemode_active' },
  { name: 'Modo avión inactivo', icon: 'airplanemode_inactive' },
  { name: 'Vuelo', icon: 'flight' },
  { name: 'Tren', icon: 'train' },
  { name: 'Barco', icon: 'directions_boat' },
  { name: 'Hotel', icon: 'hotel' },
  { name: 'Restaurante', icon: 'restaurant' },
  { name: 'Café', icon: 'local_cafe' },
  { name: 'Bar', icon: 'local_bar' },
  { name: 'Cesta de compra', icon: 'shopping_basket' },
  { name: 'Tienda', icon: 'local_grocery_store' },
  { name: 'Escuela', icon: 'school' },
  { name: 'Biblioteca', icon: 'library_books' },
  { name: 'Hospital', icon: 'local_hospital' },
  { name: 'Farmacia', icon: 'local_pharmacy' },
  { name: 'Oferta', icon: 'local_offer' },
  { name: 'Evento', icon: 'event' },
  { name: 'Rango de fecha', icon: 'date_range' },
  { name: 'Imprimir', icon: 'print' },
  { name: 'Guardar', icon: 'save' },
  { name: 'Adjuntar archivo', icon: 'attach_file' },
  { name: 'Adjuntar dinero', icon: 'attach_money' },
  { name: 'Lista', icon: 'list' },
  { name: 'Rejilla', icon: 'grid_on' },
  { name: 'Ver lista', icon: 'view_list' },
  { name: 'Ver módulo', icon: 'view_module' },
  { name: 'Ver cómodo', icon: 'view_comfy' },
  { name: 'Ver agenda', icon: 'view_agenda' },
  { name: 'Eco', icon: 'eco' },
  { name: 'Señal sin celular', icon: 'signal_cellular_off' },
  { name: 'Voz', icon: 'record_voice_over' },
  { name: 'Móvil apagado', icon: 'mobile_off' },
  { name: 'Llamada perdida', icon: 'call_missed' },
  { name: 'Guardar como', icon: 'save_alt' },
  { name: 'Mano', icon: 'pan_tool' },
  { name: 'GPS apagado', icon: 'gps_off' }
];

export var eventCodes = [
  {
    "code": "01",
    "event": "Encendido",
    "category": "Administrative"
  },
  {
    "code": "03",
    "event": "Descarga OTA Completa",
    "category": "Administrative"
  },
  {
    "code": "07",
    "event": "Despertar (Mensaje SMS)",
    "category": "Administrative"
  },
  {
    "code": "08",
    "event": "Despertar (Reinicio de Aplicación)",
    "category": "Administrative"
  },
  {
    "code": "0D",
    "event": "Comenzar Movimiento",
    "category": "Standard"
  },
  {
    "code": "0E",
    "event": "No Moviéndose",
    "category": "Standard"
  },
  {
    "code": "0F",
    "event": "Ralenti",
    "category": "Standard"
  },
  {
    "code": "10",
    "event": "Parar",
    "category": "Standard"
  },
  {
    "code": "12",
    "event": "Detección de Interferencia GPS",
    "category": "Security"
  },
  {
    "code": "13",
    "event": "Detección de Interferencia de Radio",
    "category": "Security"
  },
  {
    "code": "14",
    "event": "Encendido Apagado",
    "category": "Standard"
  },
  {
    "code": "15",
    "event": "Encendido Encendido",
    "category": "Standard"
  },
  {
    "code": "1E",
    "event": "Informe Normal Activado",
    "category": "Standard"
  },
  {
    "code": "1F",
    "event": "Informe Normal Desactivado",
    "category": "Standard"
  },
  {
    "code": "20",
    "event": "Despertar LMU",
    "category": "Administrative"
  },
  {
    "code": "21",
    "event": "GPS Adquirido",
    "category": "Security"
  },
  {
    "code": "22",
    "event": "GPS Perdido",
    "category": "Security"
  },
  {
    "code": "23",
    "event": "Comunicación Adquirida",
    "category": "Standard"
  },
  {
    "code": "24",
    "event": "Comunicación Perdida",
    "category": "Standard"
  },
  {
    "code": "25",
    "event": "Apagado de Comunicación",
    "category": "Standard"
  },
  {
    "code": "26",
    "event": "jPOD Despierto",
    "category": "Administrative"
  },
  {
    "code": "27",
    "event": "jPOD Durmiendo",
    "category": "Administrative"
  },
  {
    "code": "28",
    "event": "LMU Alimentado desde Batería de Respaldo Interna",
    "category": "Administrative"
  },
  {
    "code": "29",
    "event": "LMU Alimentado desde Fuente Externa",
    "category": "Administrative"
  },
  {
    "code": "2A",
    "event": "Nivel de Batería Bajo",
    "category": "Administrative"
  },
  {
    "code": "2B",
    "event": "Nivel de Batería Alto",
    "category": "Administrative"
  },
  {
    "code": "2C",
    "event": "ID de Conductor Leído",
    "category": "Security"
  },
  {
    "code": "2D",
    "event": "Temperatura de Debajo a Encima Sensor 1",
    "category": "Temperature"
  },
  {
    "code": "2E",
    "event": "Temperatura Permitida Sensor 1",
    "category": "Temperature"
  },
  {
    "code": "2F",
    "event": "Temperatura de Encima a Debajo Sensor 1",
    "category": "Temperature"
  },
  {
    "code": "30",
    "event": "Temperatura de Debajo a Encima Sensor 2",
    "category": "Temperature"
  },
  {
    "code": "31",
    "event": "Temperatura Permitida Sensor 2",
    "category": "Temperature"
  },
  {
    "code": "32",
    "event": "Temperatura de Encima a Debajo Sensor 2",
    "category": "Temperature"
  },
  {
    "code": "33",
    "event": "Temperatura de Debajo a Encima Sensor 3",
    "category": "Temperature"
  },
  {
    "code": "34",
    "event": "Temperatura Permitida Sensor 3",
    "category": "Temperature"
  },
  {
    "code": "35",
    "event": "Temperatura de Encima a Debajo Sensor 3",
    "category": "Temperature"
  },
  {
    "code": "36",
    "event": "MDT860 Detectado",
    "category": "Administrative"
  },
  {
    "code": "37",
    "event": "MDT860 Ya No Detectado",
    "category": "Administrative"
  },
  {
    "code": "38",
    "event": "Mensaje de Prueba MDT860",
    "category": "Administrative"
  },
  {
    "code": "3C",
    "event": "Encendido Habilitado",
    "category": "Security"
  },
  {
    "code": "3D",
    "event": "Encendido Deshabilitado",
    "category": "Security"
  },
  {
    "code": "3E",
    "event": "Aceleración Habilitada",
    "category": "Security"
  },
  {
    "code": "3F",
    "event": "Aceleración Deshabilitada",
    "category": "Security"
  },
  {
    "code": "40",
    "event": "Botón de Pánico",
    "category": "Security"
  },
  {
    "code": "41",
    "event": "Botón de Pánico",
    "category": "Security"
  },
  {
    "code": "42",
    "event": "Entrada-4 de Bajo a Alto",
    "category": "Security"
  },
  {
    "code": "43",
    "event": "Entrada-4 de Alto a Bajo",
    "category": "Security"
  },
  {
    "code": "44",
    "event": "Entrada-5 de Bajo a Alto",
    "category": "Security"
  },
  {
    "code": "45",
    "event": "Entrada-5 de Alto a Bajo",
    "category": "Security"
  },
  {
    "code": "46",
    "event": "Entrada-6 de Bajo a Alto",
    "category": "Security"
  },
  {
    "code": "47",
    "event": "Entrada-6 de Alto a Bajo",
    "category": "Security"
  },
  {
    "code": "48",
    "event": "Entrada-7 de Bajo a Alto",
    "category": "Security"
  },
  {
    "code": "49",
    "event": "Entrada-7 de Alto a Bajo",
    "category": "Security"
  },
  {
    "code": "50",
    "event": "Conductor Registrado",
    "category": "Security"
  },
  {
    "code": "51",
    "event": "Conductor Cerró Sesión",
    "category": "Security"
  },
  {
    "code": "5A",
    "event": "Velocidad de Debajo a Encima",
    "category": "Safety"
  },
  {
    "code": "5B",
    "event": "Velocidad de Encima a Debajo",
    "category": "Safety"
  },
  {
    "code": "6E",
    "event": "Aceleración Brusca",
    "category": "Safety"
  },
  {
    "code": "6F",
    "event": "Frenado Brusco",
    "category": "Safety"
  },
  {
    "code": "70",
    "event": "Giro Brusco a la Izquierda",
    "category": "Safety"
  },
  {
    "code": "71",
    "event": "Giro Brusco a la Derecha",
    "category": "Safety"
  },
  {
    "code": "72",
    "event": "Solicitud SMS Recibida (Solicitud de Escritura)",
    "category": "Administrative"
  },
  {
    "code": "73",
    "event": "Solicitud SMS Recibida (Solicitud PEG)",
    "category": "Administrative"
  },
  {
    "code": "74",
    "event": "Interferencia de Radio Ya No Detectada",
    "category": "Security"
  },
  {
    "code": "75",
    "event": "Interferencia GPS Ya No Detectada",
    "category": "Security"
  },
  {
    "code": "81",
    "event": "Entrar en Modo Hibernación",
    "category": "Administrative"
  }
];

//sort by "event" alpabethically
eventCodes = eventCodes.sort((a, b) => (a.event > b.event) ? 1 : -1);


export var alertTypes = [
  {
    name: "event_code",
    description: "el evento enviado por la unidad sea",
    nombre_esp: "Código de Evento"
  },
  {
    name: "ingnition",
    description: "el estado de la ignición sea",
    nombre_esp: "Ignición"
  },
  {
    name: "travel_time",
    description: "el tiempo en minutos que la unidad ha estado en movimiento sea",
    nombre_esp: "Tiempo de Viaje",
    scale: "minutos"
  },
  {
    name: "stay_time",
    description: "el tiempo en minutos que la unidad ha estado detenida sea",
    nombre_esp: "Tiempo de Estancia",
    scale: "minutos"
  },
  {
    name: "hdop",
    description: "la precisión de la señal GPS sea",
    nombre_esp: "Precisión GPS",

  },
  {
    name: "geo_type",
    description: "el tipo de geocerca sea",
    nombre_esp: "Tipo de Geocerca"
  },
  {
    name: "last_pos",
    description: "el tiempo en minutos desde la última posición sea",
    nombre_esp: "Última Posición",
    scale: "minutos"
  },
  /*{
    name: "travel_stop_query",
    description: "el estado de la solicitud de parada sea",
    nombre_esp: "Solicitud de parada"
  },
  {
    name: "travel_finish_query",
    description: "el estado de la solicitud de fin de ruta sea",
    nombre_esp: "Solicitud de fin de ruta"
  },
  {
    name: "travel_sos",
    description: "el estado de la solicitud de SOS de Operador sea",
    nombre_esp: "SOS Operador"
  },*/
  {
    name: "in_route_no_sig",
    description: "el tiempo en minutos que la unidad ha estado en una ruta clasificada sin señal sea",
    nombre_esp: "En Ruta Sin Señal",
    scale: "minutos"
  },
  {
    name: "route_no_sig",
    description: "la distancia en metros que la unidad ha recorrido en una ruta clasificada sin señal sea",
    nombre_esp: "Fuera de Ruta Sin Señal",
    scale: "metros" //el isuario debería escribirlo en metros pero el sistemas lo procesa en km
  },
  {
    name: "late_start",
    description: "la unidad haya comenzado su viaje fuera de la hora programada con un desfase",
    nombre_esp: "Inicio Tardío",
    scale: "minutos"
  },
  {
    name: "follow",
    description: "tenga un estado de viaje",
    nombre_esp: "Viaje Iniciado"
  },
  {
    name: "stay",
    description: "el tiempo en minutos que la unidad ha estado en una estancia sea",
    nombre_esp: "Estancia",
    scale: "minutos"
  },
  {
    name: "manual",
    description: "se ha generado una alerta manual con valor",
    nombre_esp: "Manual",
  },

  {
    name: "travel_manual",
    description: "el operador envíe una petición",
    nombre_esp: "App de Conductor",
  },
  {
    name: "geo_dest",
    description: "la unidad haya llegado al destino dentro del plan de viaje con valor",
    nombre_esp: "Geocerca Destino",
  },
  {
    name: "route_distance",
    description: "la unidad ha alcanzado una distancia en metros fuera de la ruta indicada (de haber ruta)",
    nombre_esp: "Distancia ruta",
    scale: "metros"
  },
  {
    name: "vel",
    description: "la velocidad de la unidad sea",
    nombre_esp: "Velocidad",
    scale: "km/h"
  },
  {
    name: "vin",
    description: "el voltaje de la batería interna sea",
    nombre_esp: "Voltaje batería interna",
    scale: "V"
  },
  {
    name: "vout",
    description: "el voltaje de la batería externa sea",
    nombre_esp: "Voltaje batería externa",
    scale: "V"
  },
  {
    name: "temp1",
    description: "la temperatura del sensor 1 sea",
    nombre_esp: "Temperatura Sensor 1",
    scale: "°C"
  },
  {
    name: "temp2",
    description: "la temperatura del sensor 2 sea",
    nombre_esp: "Temperatura Sensor 2",
    scale: "°C"
  },
  {
    name: "temp3",
    description: "la temperatura del sensor 3 sea",
    nombre_esp: "Temperatura Sensor 3",
    scale: "°C"
  }, 
  {
    name: "hos",
    description: "el estado del horario de servicio sea",
    nombre_esp: "Horas de Servicio",
  }
];
//sort alert types by "nombre_esp" alpabethically
alertTypes = alertTypes.sort((a, b) => (a.nombre_esp > b.nombre_esp) ? 1 : -1);
//list containing alert tyupes with scale in meters
export var alertTypesMeters = alertTypes.filter(alert => alert.scale === "metros");

export const contactNums = writable([]);
export const contactEmails = writable([]);
export var eventsAlerts = writable([]);
export const formData = writable({});

export function generateJson() {
  let data = {};
  let formTwo = {};
  let formThree = {};
  let formFour = {};
  let formFive = {};
  let formSix = {};

  // Obtener datos del store formData
  formOneData.subscribe(value => {
    data = value;
  })();

  // Obtener datos del store formTwoData
  formTwoData.subscribe(value => {
    formTwo = value
  })();

  // Obtener datos del store formThreeData
  formThreeData.subscribe(value => {
    //console.log("formThreeData", value);
    formThree = value;
  })();

  formFourData.subscribe(value => {
    formFour = value;
  })();

  // Obtener datos del store formThreeData
  formFiveData.subscribe(value => {
    formFive = value;
  })();

  formSixData.subscribe(value => {
    formSix = value;
  })();

  // Imprimir datos para depuración
  //console.log("Datos de formData:", data);
  //console.log("Datos de formThreeData:", formThree);

  // Verificar que data y formThree sean válidos
  /*if (!data || !formThree) {
    console.error("Error: los datos de formData o formThreeData no están disponibles.");
    return;
  }*/



  // Estructurar el JSON para event_alert
  const eventAlert = {
    count: formThree.alertTrend,
    counter: formThree.conditionGroups.map(group => {
      const conditionObj = {};
      group.conditions.forEach(condition => {
        conditionObj[condition.event] = {
          type: condition.operator,
          val: condition.value
        };
      });
      return conditionObj;
    }),
    discounter: [] // Aquí podrías añadir lógica para los discounters si es necesario
  };

  // Estructurar el JSON para cron_alert
  const cronAlert = {
    count: formFive.alertTrend,
    counter: formFive.conditionGroups.map(group => {
      const conditionObj = {};
      group.conditions.forEach(condition => {
        conditionObj[condition.event] = {
          type: condition.operator,
          val: condition.value
        };
      });
      return conditionObj;
    }),
    discounter: [] // Aquí podrías añadir lógica para los discounters si es necesario
  };

  //de las alertas en evenCounter, evenDiscounter, cronCounter, cronDiscounter coneritr los valores con escala de metros a km
  function formatGroup(group) {
    group.forEach(conditions => {
      const alertTypesMetersNames = alertTypesMeters.map(alert => alert.name);

      Object.keys(conditions).forEach(key => {
        if (alertTypesMetersNames.includes(key) && typeof conditions[key] === 'object' && conditions[key].hasOwnProperty('val')) {

          if (typeof conditions[key].val === 'object') {
            Object.keys(conditions[key].val).forEach(deviceType => {
              conditions[key].val[deviceType] = conditions[key].val[deviceType] / 1000;
            });

          } else {
            conditions[key].val = conditions[key].val / 1000;
          }


        }
      });
    });

    return group;
  }


  // Crear el JSON final
  let jsonData = {
    name: data.alertName,
    alertid: data.alertId || generateUUID(),
    severity: data.alertSeverity,
    description: data.alertDescription,
    chat_message: formTwo.alertChatMessage,
    frequency_max: data.alertFrequencyMax,
    freq_counter_min: data.alertFreqCounterMin,
    freq_discounter_min: data.alertFreqDiscounterMin,
    protocol: data.alertProtocol,
    message: formTwo.alertMessage,
    report_tels: formTwo.contactNums,
    emails: formTwo.contactEmails,
    disabled: !data.alertActive,
    icon: data.alertIcon,
    event_alert: {
      count: eventAlert.count,
      counter: formatGroup(get(evenCounter)),
      discounter: formatGroup(get(evenDiscounter))
    },
    cron_alert: {
      count: cronAlert.count,
      counter: formatGroup(get(cronCounter)),
      discounter: formatGroup(get(cronDiscounter))
    }
  };

  //get extra fields
  const extraFields = extraFieldsMap.get(jsonData.alertid);
  //console.log("campos extra", extraFields);
  if (extraFields) {
    jsonData = { ...jsonData, ...extraFields };
  }

  //console.log("JSON generado:", jsonData);
  return jsonData;
}

export function updateExistingAlert() {
  console.log("updateExistingAlert");

  try {
    const alertId = get(orgJson).alertid;
    let modifiedJson = generateJson();

    if (!modifiedJson) {
      console.error("Error: el JSON generado no es válido.");
      return false;
    }

    let alertIndex = -1;

    get(alerts).forEach((alert, index) => {
      if (alert.alertid === alertId) {
        alertIndex = index;
        modifiedJson.alertid = alertId;

        // Recuperar los campos adicionales (extra fields) para esta alerta
        const extraFields = extraFieldsMap.get(alertId);
        if (extraFields) {
          // Fusionar los campos adicionales con el JSON modificado
          modifiedJson = { ...modifiedJson, ...extraFields };
        }
      }
    });

    if (alertIndex === -1) {
      console.error("Error: alerta no encontrada.");
      return false;
    }

    const updatedAlerts = get(alerts).map((alert, index) =>
      index === alertIndex ? modifiedJson : alert
    );

    alerts.set(updatedAlerts);

    // Actualizar el JSON original y devovler al padre
    returnUpdatedAlerts();

    return true;
  } catch (error) {
    console.error("Error al actualizar alerta:", error);
    return false;
  }
}

export function addNewAlert(json) {
  console.log("addNewAlert");

  let newAlert = json;

  if (!newAlert) {
    console.error("Error: el JSON generado no es válido.");
    return false;
  }

  let updatedAlerts = get(alerts);
  updatedAlerts.push(newAlert);

  alerts.set(updatedAlerts);

  // Actualizar el JSON original y devovler al padre
  returnUpdatedAlerts();

  return true;
}

function returnUpdatedAlerts() {
  let alertsToReturn = getFinalAlertsJson();
  console.log("jsonToReturn update:", alertsToReturn);

  //SEND DATA TO PARENT WINDOW
  sendMessageToParent({ type: "alerts_update", body: alertsToReturn });

}



export function fillFromJson(jsonData = null) {
  let alertId;
  if (jsonData) {
    isEditMode.set(true);
    const identifiedDeviceTypes = new Set();
    const extraFields = {};
    alertId = jsonData.alertid
    // Extraer campos adicionales que no estás manejando explícitamente
    for (const key in jsonData) {
      if (
        ![
          "name",
          "alertid",
          "severity",
          "description",
          "message",
          "chat_message",
          "disabled",
          "frequency_max",
          "freq_counter_min",
          "freq_discounter_min",
          "protocol",
          "icon",
          "cron_alert",
          "event_alert",
          "report_tels",
          "emails"
        ].includes(key)
      ) {
        extraFields[key] = jsonData[key];
      }
    }

    // Almacenar los campos adicionales en el mapa
    if (Object.keys(extraFields).length > 0) {
      extraFieldsMap.set(alertId, extraFields);
    }
    console.log("Campos id:", alertId);

    //console.log("Campos adicionales:", extraFields);
    //console.log("Campos map:", extraFieldsMap);



    // Función para extraer tipos de dispositivos de las alertas
    function extractDeviceTypesFromAlerts(alerts) {
      alerts.forEach(group => {
        Object.values(group).forEach(condition => {
          if (typeof condition.val === 'object') {
            Object.keys(condition.val).forEach(deviceType => {
              identifiedDeviceTypes.add(deviceType);
            });
          } else {
            // Convertir valores no objetos en un objeto con 'default'
            let conditionVal = condition.val;
            condition.val = { default: conditionVal };
          }
        });
      });
    }

    // Extraer tipos de dispositivos de event_alert y cron_alert
    if (jsonData.event_alert && jsonData.event_alert.counter) {
      extractDeviceTypesFromAlerts(jsonData.event_alert.counter);
    }

    if (jsonData.cron_alert && jsonData.cron_alert.counter) {
      extractDeviceTypesFromAlerts(jsonData.cron_alert.counter);
    }

    // Actualizar devicesTypes en el store
    let updatedDevicesTypes = get(devicesTypes).map(device => ({
      ...device,
      checked: identifiedDeviceTypes.has(device.value)
    }));
    devicesTypes.set(updatedDevicesTypes);

    // Inicializar datos del formulario 1
    formOneData.set({
      alertId: alertId,
      alertName: jsonData.name,
      alertDescription: jsonData.description,
      alertDeviceTypes: updatedDevicesTypes,
      alertFrequencyMax: jsonData.frequency_max,
      alertFreqCounterMin: jsonData.freq_counter_min,
      alertFreqDiscounterMin: jsonData.freq_discounter_min,
      alertProtocol: jsonData.protocol,
      alertSeverity: jsonData.severity,
      alertIcon: jsonData.icon,
      alertActive: !jsonData.disabled
    });

    // Inicializar datos del formulario 2
    formTwoData.set({
      contactNums: jsonData.report_tels || jsonData.tels || [],
      contactEmails: jsonData.emails || jsonData.report_emails || [],
      alertMessage: jsonData.message || '',
      alertChatMessage: jsonData.chat_message || ''
    });

    // Función para inicializar filas (rows) con datos desde las condiciones
    function initializeRows(conditions) {
      return conditions.map(condition => {
        let selectedEventIndex = alertTypes.findIndex(type => type.name === condition.event);
        let operatorIndex = operators.findIndex(op => op.value === condition.operator);

        let selectedValueIndexes = get(devicesTypes).map(() => 0);
        let deviceValues = get(devicesTypes).map(device => {
          const inputValue = condition.value[device.value] !== undefined && condition.value[device.value] !== null
            ? condition.value[device.value]
            : condition.value.default;  // Aquí se captura el valor por defecto si no existe un valor específico para el dispositivo.

          return {
            ...device,
            inputValue: inputValue
          };
        });

        if (selectedEventIndex === -1) {
          selectedEventIndex = 0;
        }

        // Ajuste especial para ingnition
        if (alertTypes[selectedEventIndex].name === "ingnition") {
          selectedValueIndexes = get(devicesTypes).map(device => {
            const index = ingnitionValues.findIndex(val => val.value === (condition.value[device.value] || condition.value.default));
            return index !== -1 ? index : 0; // Si no encuentra el índice, usar 0
          });
        } else if (alertTypes[selectedEventIndex].name === "event_code") {
          selectedValueIndexes = get(devicesTypes).map(device => {
            const index = eventCodes.findIndex(val => val.code === (condition.value[device.value] || condition.value.default));
            return index !== -1 ? index : 0;
          });
        } else if (alertTypes[selectedEventIndex].name === "hdop") {
          selectedValueIndexes = get(devicesTypes).map(device => {
            const index = hdopValues.findIndex(val => val.value === (condition.value[device.value] || condition.value.default));
            return index !== -1 ? index : 0;
          });
        } else if (alertTypes[selectedEventIndex].name === "geo_type") {
          selectedValueIndexes = get(devicesTypes).map(device => {
            const index = geoValues.findIndex(val => val.value === (condition.value[device.value] || condition.value.default));
            return index !== -1 ? index : 0;
          });
          operatorIndex = geoOperators.findIndex(op => op.value === condition.operator);

        } else if (alertTypes[selectedEventIndex].name === "manual") {
          selectedValueIndexes = get(devicesTypes).map(device => {
            const index = manualValues.findIndex(val => val.value === (condition.value[device.value] || condition.value.default));
            return index !== -1 ? index : 0;
          });

        } else if (alertTypes[selectedEventIndex].name === "travel_manual") {
          selectedValueIndexes = get(devicesTypes).map(device => {
            const index = travelManualValues.findIndex(val => val.value === (condition.value[device.value] || condition.value.default));
            return index !== -1 ? index : 0;
          });

        } else if (alertTypes[selectedEventIndex].name === "geo_dest") {
          selectedValueIndexes = get(devicesTypes).map(device => {
            const index = geoDestValues.findIndex(val => val.value === (condition.value[device.value] || condition.value.default));
            return index !== -1 ? index : 0;
          });
        } else if (alertTypes[selectedEventIndex].name === "travel_stop_query") {
          selectedValueIndexes = get(devicesTypes).map(device => {
            const index = travelStopQueryValues.findIndex(val => val.value === (condition.value[device.value] || condition.value.default));
            return index !== -1 ? index : 0;
          });
        } else if (alertTypes[selectedEventIndex].name === "travel_finish_query") {
          selectedValueIndexes = get(devicesTypes).map(device => {
            const index = travelFinishQueryValues.findIndex(val => val.value === (condition.value[device.value] || condition.value.default));
            return index !== -1 ? index : 0;
          });

        } else if (alertTypes[selectedEventIndex].name === "travel_sos") {
          selectedValueIndexes = get(devicesTypes).map(device => {
            const index = travelSosQueryValues.findIndex(val => val.value === (condition.value[device.value] || condition.value.default));
            return index !== -1 ? index : 0;
          });
        } else if (alertTypes[selectedEventIndex].name === "hos") {
          selectedValueIndexes = get(devicesTypes).map(device => {
            const index = hosValues.findIndex(val => val.value === (condition.value[device.value] || condition.value.default));
            return index !== -1 ? index : 0;
          });
        }

        //revisar si están en la lista de escala en metros
        if (alertTypesMeters.includes(alertTypes[selectedEventIndex])) {
          //convertir de km a metros
          deviceValues = deviceValues.map(device => {
            return {
              ...device,
              inputValue: device.inputValue * 1000
            };
          });
        }

        return {
          selectedEventIndex,
          operatorIndex,
          selectedValue: condition.value.default !== undefined && condition.value.default !== null
            ? condition.value.default
            : "",
          selectedValueIndexes,
          deviceValues
        };
      });
    }



    // Asignar datos a formThreeData, formFourData, formFiveData, formSixData
    formThreeData.set({
      alertTrend: jsonData.event_alert.count,
      conditionGroups: jsonData.event_alert.counter.map(group => ({
        conditions: Object.entries(group).map(([event, details]) => ({
          event,
          operator: details.type,
          value: details.val
        })),
        rows: initializeRows(Object.entries(group).map(([event, details]) => ({
          event,
          operator: details.type,
          value: details.val
        })))
      }))
    });

    formFourData.set({
      alertTrend: jsonData.event_alert.count,
      conditionGroups: jsonData.event_alert.discounter.map(group => ({
        conditions: Object.entries(group).map(([event, details]) => ({
          event,
          operator: details.type,
          value: details.val
        })),
        rows: initializeRows(Object.entries(group).map(([event, details]) => ({
          event,
          operator: details.type,
          value: details.val
        })))
      }))
    });

    formFiveData.set({
      alertTrend: jsonData.cron_alert.count,
      conditionGroups: jsonData.cron_alert.counter.map(group => ({
        conditions: Object.entries(group).map(([event, details]) => ({
          event,
          operator: details.type,
          value: details.val
        })),
        rows: initializeRows(Object.entries(group).map(([event, details]) => ({
          event,
          operator: details.type,
          value: details.val
        })))
      }))
    });

    formSixData.set({
      alertTrend: jsonData.cron_alert.count,
      conditionGroups: jsonData.cron_alert.discounter.map(group => ({
        conditions: Object.entries(group).map(([event, details]) => ({
          event,
          operator: details.type,
          value: details.val
        })),
        rows: initializeRows(Object.entries(group).map(([event, details]) => ({
          event,
          operator: details.type,
          value: details.val
        })))
      }))
    });

    evenCounter.set(jsonData.event_alert.counter || []);
    evenDiscounter.set(jsonData.event_alert.discounter || []);
    cronCounter.set(jsonData.cron_alert.counter || []);
    cronDiscounter.set(jsonData.cron_alert.discounter || []);

  } else {
    alertId = generateUUID();
    isEditMode.set(false);
    // Restablecer todos los stores y variables a sus valores iniciales
    devicesTypes.set([
      { name: 'FS', value: 'FS', checked: false },
      { name: 'TMCI', value: 'TMCI', checked: false },
      { name: 'IVG', value: 'IVG', checked: false },
      { name: 'GNX', value: 'GNX', checked: false },
      { name: 'Por defecto', value: 'default', checked: true }
    ]);

    formOneData.set({
      alertId: alertId,
      alertName: "",
      alertDescription: "",
      alertDeviceTypes: get(devicesTypes),
      alertFrequencyMax: 5,
      alertFreqCounterMin: 1,
      alertFreqDiscounterMin: 1,
      alertProtocol: 0,
      alertSeverity: 0,
      alertIcon: "",
      alertActive: true
    });

    formTwoData.set({
      contactNums: [],
      contactEmails: [],
      alertMessage: "",
      alertChatMessage: ""
    });

    formThreeData.set({
      alertTrend: 1,
      conditionGroups: []
    });

    formFourData.set({
      alertTrend: 1,
      conditionGroups: []
    });

    formFiveData.set({
      alertTrend: 1,
      conditionGroups: []
    });

    formSixData.set({
      alertTrend: 1,
      conditionGroups: []
    });

    evenCounter.set([]);
    evenDiscounter.set([]);
    cronCounter.set([]);
    cronDiscounter.set([]);
  }
}



export function downloadAlertsAsJson() {
  // Obtén los datos actuales de alerts
  const alertsData = get(alerts);

  // Conviértelo en una cadena JSON con un formato legible
  const jsonString = JSON.stringify(alertsData, null, 2);

  // Crea un blob con el tipo de archivo JSON
  const blob = new Blob([jsonString], { type: 'application/json' });

  // Crea una URL para el blob
  const url = URL.createObjectURL(blob);

  // Crea un elemento <a> invisible para desencadenar la descarga
  const a = document.createElement('a');
  a.href = url;
  a.download = 'alerts.json';

  // Añade el elemento al DOM y simula un clic
  document.body.appendChild(a);
  a.click();

  // Limpia el DOM y revoca el objeto URL
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export function deleteAlert(index) {
  alerts.update(items => {
    items.splice(index, 1);
    return items;
  });
}

export function formatUploadJson(jsonData) {
  if (!Array.isArray(jsonData)) {
    throw new Error("El JSON debe contener un array de alertas.");
  }

  // Función para actualizar 'val' para que sea un objeto con "default" y los tipos de dispositivos
  function updateVal(val, deviceTypes) {
    if (typeof val === 'object' && val !== null) {
      // Si ya es un objeto, asegurarse de que todos los deviceTypes estén presentes
      const updatedVal = { default: val.default || val };
      deviceTypes.forEach(device => {
        updatedVal[device] = val[device] !== undefined ? val[device] : updatedVal.default;
      });
      return updatedVal;
    } else {
      // Si no es un objeto, convertirlo en un objeto con "default"
      const updatedVal = { default: val };
      deviceTypes.forEach(device => {
        updatedVal[device] = val;
      });
      return updatedVal;
    }
  }

  // Función para procesar las alertas y actualizar los 'val' en 'counter' y 'discounter'
  function processAlert(alert) {
    const deviceTypes = new Set();

    // Extraer todos los tipos de dispositivos usados en la alerta
    function extractDeviceTypesFromConditions(conditions) {
      conditions.forEach(condition => {
        Object.values(condition).forEach(value => {
          if (typeof value.val === 'object' && value.val !== null) {
            Object.keys(value.val).forEach(deviceType => {
              deviceTypes.add(deviceType);
            });
          } else {
            // Convertir valores no objetos en un objeto con 'default'
            value.val = { default: value.val };
            deviceTypes.add('default');
          }
        });
      });
    }

    // Actualizar los 'val' en 'counter' y 'discounter'
    function updateConditions(conditions) {
      return conditions.map(condition => {
        Object.values(condition).forEach(value => {
          value.val = updateVal(value.val, [...deviceTypes]);
          value.deviceValues = Object.keys(value.val).map(deviceType => ({
            value: deviceType,
            inputValue: value.val[deviceType],
            checked: deviceType !== 'default'
          }));
        });
        // si el valor del input pertenece a un tipo de alerta con escala en metros, convertir de km a metros
        //console.log("condition", condition);
        //revisar si la condition tine el objeto con alguna key que se encuentre en alertTypesMeters
        /* Object.keys(condition).forEach(key => {
           const alertType = alertTypesMeters.find(type => type.name === key);
           if (alertType && alertType.scale) {
             if (alertType.scale === "metros") {
 
               Object.keys(condition[key].val).forEach(deviceType => {
                 condition[key].val[deviceType] = condition[key].val[deviceType] * 1000;
               });
 
             }
           }
         });*/
        return condition;
      });
    }

    // Extraer tipos de dispositivos de event_alert y cron_alert
    if (alert.event_alert && alert.event_alert.counter) {
      extractDeviceTypesFromConditions(alert.event_alert.counter);
    }

    if (alert.cron_alert && alert.cron_alert.counter) {
      extractDeviceTypesFromConditions(alert.cron_alert.counter);
    }

    // Actualizar los 'val' en 'counter' y 'discounter' para event_alert y cron_alert
    if (alert.event_alert && alert.event_alert.counter) {
      alert.event_alert.counter = updateConditions(alert.event_alert.counter);
    }

    if (alert.event_alert && alert.event_alert.discounter) {
      alert.event_alert.discounter = updateConditions(alert.event_alert.discounter);
    }

    if (alert.cron_alert && alert.cron_alert.counter) {
      alert.cron_alert.counter = updateConditions(alert.cron_alert.counter);
    }

    if (alert.cron_alert && alert.cron_alert.discounter) {
      alert.cron_alert.discounter = updateConditions(alert.cron_alert.discounter);
    }

    return alert;
  }

  return jsonData.map(alert => processAlert(alert));
}
export function getFinalAlertsJson() {
  const alertsData = get(alerts);
  let finalAlerts = formatUploadJson(alertsData);

  // Aplicar extraFields si existen
  finalAlerts = finalAlerts.map(alert => {
    const extraFields = extraFieldsMap.get(alert.alertid);
    if (extraFields) {
      alert = { ...alert, ...extraFields };
    }
    return alert;
  });

  // Remover deviceValues de cada counter y discounter en finalAlerts
  finalAlerts.forEach(alert => {
    ['cron_alert', 'event_alert'].forEach(alertType => {
      ['counter', 'discounter'].forEach(conditionType => {
        if (alert[alertType] && alert[alertType][conditionType]) {
          alert[alertType][conditionType].forEach(condition => {
            Object.keys(condition).forEach(key => {

              // Convertir strings numéricos a valores numéricos dentro de 'val'
              if (condition[key].val && typeof condition[key].val === 'object') {
                Object.keys(condition[key].val).forEach(deviceType => {
                  if (typeof condition[key].val[deviceType] === 'string' && !isNaN(Number(condition[key].val[deviceType]))) {
                    condition[key].val[deviceType] = Number(condition[key].val[deviceType]);
                  }
                });
              }

              // Convertir 'val' si sólo tiene 'default' como única clave
              if (
                condition[key].val &&
                typeof condition[key].val === 'object' &&
                Object.keys(condition[key].val).length === 1 &&
                'default' in condition[key].val
              ) {
                console.log("igualando a default", condition[key].val);
                condition[key].val = condition[key].val.default;
                console.log("igualando a default nuevo", condition[key].val);
              }

              // Remover deviceValues si está presente
              if (condition[key].deviceValues) {
                delete condition[key].deviceValues;
              }
            });
          });
        }
      });
    });
  });

  // Retornar finalAlerts sin re-formatearlo
  return finalAlerts;
}



export function sendMessageToParent(message) {
  console.log("Sending message to parent:", message);
  window.parent.postMessage(message, "*");
  //irvin me envía el url padre desde url_post desde el ifrmae
}

export function generateUUID() {
  // Implementar una función para generar un UUID
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getUrlParams() {
  const params = new URLSearchParams(window.location.search);
  const entries = {};
  for (const [key, value] of params.entries()) {
    entries[key] = value;
  }
  return entries;
}
