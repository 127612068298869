<script>
  import { onMount } from "svelte";
  import { generateJson } from "./store.js"; // Ajusta el camino al archivo correcto
  import toast from "svelte-french-toast";

  
</script>

<!--<div class="d-flex justify-content-between">
    <h2>Alerta resultate</h2>
</div>-->
<!--<div class="json-viewer mb-3">
  {#if Object.keys(jsonData).length > 0}
    <code>{JSON.stringify(jsonData, null, 2)}</code>
  {:else}
    <p>No se ha generado ningún JSON aún.</p>
  {/if}
</div>-->

<div
  class="mb-3 mt-3 d-flex align-items-center justify-content-center"
  style="min-height: 500px;"
>
  <div>
    <div class="d-flex justify-content-center">
      <h2 style="">¡Alerta configurada con éxito!</h2>
    </div>
    <div class="d-flex justify-content-center">
      <h3 style="">Guarda los cambios para continuar</h3>
    </div>
    <div class="d-flex justify-content-center">
      <h2 style="">😊</h2>
    </div>
  </div>
  <!--Botón para copiar json el el portapapeles-->
</div>

<style>
  .json-viewer {
    white-space: pre-wrap;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 5px;
    font-family: monospace;
    max-height: 500px;
    overflow: auto;
  }
</style>
