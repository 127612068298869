<script>
  import {
    contactNums,
    contactEmails,
    formOneData,
    formTwoData,
    isFormCompleted,
    isDataModified,
  } from "./store.js";
  import { onMount } from "svelte";
  import toast from "svelte-french-toast";
  import { init } from "svelte/internal";

  let localData;
  let initialData;

  function checkForModifications() {
    if (!$isDataModified) {
      isDataModified.set(JSON.stringify(localData) !== JSON.stringify(initialData));
    }
  }

  formTwoData.subscribe((value) => {
    localData = { ...value };
  });

  let newContactNum = "";
  let newContactEmail = "";

  const UNIDAD_SCAPE = "{unidad}";

  function validateForm() {
    const isCompleted =
      //localData.contactNums.length > 0 &&
      //localData.contactEmails.length > 0 &&
      localData.alertMessage.trim() !== "" 
      //&&
      //localData.alertChatMessage.trim() !== "";
    isFormCompleted.set(isCompleted);
  }

  function updateFormTwoData() {
    formTwoData.set(localData); // Actualizar el store con los datos locales
    checkForModifications();
  }

  function addToContactNums() {
    if (/^\d{10}$/.test(newContactNum)) {
      localData.contactNums.push(newContactNum);
      updateFormTwoData();
      newContactNum = "";
      validateForm();
    } else {
      toast.error(
        "Número telefónico inválido. Debe ser un número de 10 dígitos."
      );
    }
  }

  function removeFromContactNums(index) {
    localData.contactNums.splice(index, 1);
    updateFormTwoData();
    validateForm();
  }

  function addToContactEmails() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newContactEmail)) {
      localData.contactEmails.push(newContactEmail);
      updateFormTwoData();
      newContactEmail = "";
      validateForm();
    } else {
      toast.error("Email inválido. Debe ser un email válido.");
    }
  }

  function removeFromContactEmails(index) {
    localData.contactEmails.splice(index, 1);
    updateFormTwoData();
    validateForm();
  }

  function updateAlertMessage(message) {
    localData.alertMessage = message;
    updateFormTwoData();
    validateForm();
  } 

  function updateAlertChatMessage(message) {
    localData.alertChatMessage = message;
    updateFormTwoData();
    validateForm();
  }

  onMount(() => {
    initialData = { ...localData };
    console.log("initialData", initialData);
    
    //default values para Mensaje de alerta y Mensaje de alerta para chat
    if (initialData.alertMessage === "") {
      console.log("FormOneData", $formOneData);  
      
      //llenaro con el nombre de la alerta
      localData.alertMessage = `Alerta para '${$formOneData.alertName}'`;
      localData.alertChatMessage = `Mensaje para '${$formOneData.alertName}'`;
    }
    validateForm();
  });
</script>

<h2>Contacto</h2>
<hr />
<div id="form-content">
  <form>
    <!--Numeros de contacto-->
    <div class="mb-3">
      <label for="contactNums" class="form-label">Números de contacto</label>

      {#each localData.contactNums as num, index}
        <div class="input-group flex-nowrap mb-1">
          <input
            type="text"
            class="form-control"
            placeholder="Número"
            aria-label="Número"
            aria-describedby="addon-wrapping"
            value={num}
            disabled
          />
          <span>
            <button
              class="btn btn-danger"
              type="button"
              on:click={() => removeFromContactNums(index)}
              ><i class="bi bi-dash-square"></i></button
            >
          </span>
        </div>
      {/each}

      {#if localData.contactNums.length === 0}
        <div class="text-danger">
          Debe agregar al menos un número de contacto.
        </div>
      {/if}

      <div class="input-group flex-nowrap">
        <span class="input-group-text" id="addon-wrapping"
          ><i class="bi bi-telephone-plus"></i></span
        >
        <input
          type="text"
          class="form-control"
          placeholder="Ej. 1234567890"
          aria-label="Número"
          aria-describedby="addon-wrapping"
          bind:value={newContactNum}
        />
        <span>
          <button
            class="btn btn-primary"
            type="button"
            on:click={addToContactNums}
            ><i class="bi bi-plus-square"></i></button
          >
        </span>
      </div>

      <div id="contactNumsHelp" class="form-text">
        Escribe los números de contacto a los que se enviará la alerta
      </div>
    </div>

    <!--Emails de contacto-->
    <div class="mb-3">
      <label for="contactEmails" class="form-label">Emails de contacto</label>

      {#each localData.contactEmails as email, index}
        <div class="input-group flex-nowrap mb-1">
          <input
            type="email"
            class="form-control"
            placeholder="Email"
            aria-label="Email"
            aria-describedby="addon-wrapping"
            value={email}
            disabled
          />
          <span>
            <button
              class="btn btn-danger"
              type="button"
              on:click={() => removeFromContactEmails(index)}
              ><i class="bi bi-dash-square"></i></button
            >
          </span>
        </div>
      {/each}

      {#if localData.contactEmails.length === 0}
        <div class="text-danger">
          Debe agregar al menos un email de contacto.
        </div>
      {/if}

      <div class="input-group flex-nowrap">
        <span class="input-group-text" id="addon-wrapping"
          ><i class="bi bi-envelope"></i></span
        >
        <input
          type="email"
          class="form-control"
          id="contactEmails"
          aria-describedby="contactEmailsHelp"
          placeholder="Ej. email@dominio.com"
          bind:value={newContactEmail}
        />
        <span>
          <button
            class="btn btn-primary"
            type="button"
            on:click={addToContactEmails}
            ><i class="bi bi-plus-square"></i></button
          >
        </span>
      </div>
      <div id="contactEmailsHelp" class="form-text">
        Escribe los emails de contacto separados por coma
      </div>
    </div>

    <!--Mensaje de alerta-->
    <div class="mb-3">
      <label for="alertMessage" class="form-label">Mensaje de alerta</label>
      <input
        type="text"
        class="form-control"
        id="alertMessage"
        aria-describedby="alertMessageHelp"
        placeholder="Alerta en la unidad {UNIDAD_SCAPE}"
        bind:value={localData.alertMessage}
        on:input={(e) => updateAlertMessage(e.target.value)}
      />
      <div id="alertMessageHelp" class="form-text">
        Escribe el mensaje que se mostrará en la alerta. Para incluir el id de
        la unidad, usa la clave: "{UNIDAD_SCAPE}"
      </div>
    </div>

    <!--Mensaje de alerta para chat-->
    <div class="mb-3">
      <label for="alertChatMessage" class="form-label"
        >Mensaje de alerta para chat</label
      >
      <input
        type="text"
        class="form-control"
        id="alertChatMessage"
        aria-describedby="alertChatMessageHelp"
        bind:value={localData.alertChatMessage}
        on:input={(e) => updateAlertChatMessage(e.target.value)}
      />
      <div id="alertChatMessageHelp" class="form-text">
        Escribe el mensaje que se enviará al chat del operador
      </div>
    </div>
  </form>
</div>
