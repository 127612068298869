<script>
  import {
    devicesTypes,
    formOneData,
    isDataModified,
    isFormCompleted,
    materialIcons,
  } from "./store.js";
  import { onMount } from "svelte";

  let localData = {
    alertName: "",
    alertDescription: "",
    alertDeviceTypes: [],
    alertFrequencyMax: 0,
    alertFreqCounterMin: 0,
    alertFreqDiscounterMin: 0,
    alertProtocol: 0,
    alertSeverity: 0,
    alertIcon: "",
    alertActive: true,
  };

  let initialData;

  function checkForModifications() {
    if (!$isDataModified) {
      isDataModified.set(
        JSON.stringify(localData) !== JSON.stringify(initialData)
      );
    }
  }

  formOneData.subscribe((value) => {
    localData = { ...localData, ...value };
  });

  $: localData.alertDeviceTypes = $devicesTypes.map((device) => ({
    ...device,
    checked: device.checked || false,
  }));

  function validateForm() {
    const isCompleted =
      localData.alertName.trim() !== "" &&
      //localData.alertDescription.trim() !== "" &&
      localData.alertIcon !== "" &&
      !isNaN(localData.alertFrequencyMax) &&
      !isNaN(localData.alertFreqCounterMin) &&
      !isNaN(localData.alertFreqDiscounterMin) &&
      !isNaN(localData.alertProtocol) &&
      localData.alertFrequencyMax >= -1 &&
      localData.alertFreqCounterMin >= -1 &&
      localData.alertFreqDiscounterMin >= -1 &&
      localData.alertProtocol >= 0;

    isFormCompleted.set(isCompleted);
  }

  function updateFormOneData() {
    formOneData.set(localData); // Actualizar el store con los datos locales
    checkForModifications();
  }

  onMount(() => {
    validateForm(); // Validar el formulario al montar el componente
  });
</script>

<h3>Identificación</h3>
<hr />
<div id="form-content">
  <form>
    <div class="mb-3">
      <label for="alertName" class="form-label">Nombre de la alerta</label>
      <input
        type="text"
        class="form-control"
        id="alertName"
        aria-describedby="alertNameHelp"
        bind:value={localData.alertName}
        on:input={() => {
          validateForm();
          updateFormOneData();
        }}
      />
      <div id="alertNameHelp" class="form-text">
        Escribe un nombre para identificar la alerta
      </div>
    </div>

    <div class="mb-3">
      <div class="d-flex">
        <label for="alertIcon" class="form-label me-1">Icono </label>
        <div class="material-symbols-outlined">
          {localData.alertIcon}
        </div>
      </div>
      <select
        class="form-select"
        id="alertIcon"
        aria-describedby="alertIconHelp"
        bind:value={localData.alertIcon}
        on:change={() => {
          validateForm();
          updateFormOneData();
        }}
      >
        {#each materialIcons as icon}
          <option value={icon.icon}>
            {icon.name}
          </option>
        {/each}
      </select>
      <div id="alertIconHelp" class="form-text">
        Selecciona el icono que se mostrará en la alerta
      </div>
    </div>

    <!-- Accordion for advanced settings -->
    <div class="accordion" id="advancedSettingsAccordion">
      <div class="accordion-item">
        <h2 class="accordion-header" id="advancedSettingsHeader">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#advancedSettings"
            aria-expanded="false"
            aria-controls="advancedSettings"
          >
            Avanzadas
          </button>
        </h2>
        <div
          id="advancedSettings"
          class="accordion-collapse collapse"
          aria-labelledby="advancedSettingsHeader"
          data-bs-parent="#advancedSettingsAccordion"
        >
          <div class="accordion-body">
            <!-- Advanced Settings Content -->
            <div class="mb-3">
              <label for="alertDescription" class="form-label">Descripción de la alerta</label>
              <textarea
                class="form-control"
                id="alertDescription"
                rows="3"
                aria-describedby="alertDescriptionHelp"
                bind:value={localData.alertDescription}
                on:input={() => {
                  validateForm();
                  updateFormOneData();
                }}
              ></textarea>
              <div id="alertDescriptionHelp" class="form-text">
                Escribe una descripción para la alerta
              </div>
            </div>

            <!-- Agregar tipos de dispositivos -->
            <div class="mb-3">
              <label for="alertDeviceType" class="form-label">Tipos de dispositivos específicos</label>
              <div class="d-flex justify-content-start">
                {#each $devicesTypes as device, index}
                  {#if device.value !== "default"}
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        bind:checked={$devicesTypes[index].checked}
                        id={device.value}
                        on:change={() => {
                          validateForm();
                          updateFormOneData();
                        }}
                      />
                      <label class="form-check-label" for={device.value}>
                        {device.name}
                      </label>
                    </div>
                  {/if}
                {/each}
              </div>
              <div id="alertDeviceTypeHelp" class="form-text">
                De no especificar, el valor "default" será asignado a estos dispositivos.
              </div>
            </div>

            <div class="mb-3">
              <label for="alertSeverity" class="form-label">Severidad</label>
              <div class="d-flex justify-content-between">
                <span class="ms-3 me-3">Mínima</span>
                <input
                  type="range"
                  class="form-range"
                  style="border: none;"
                  id="alertSeverity"
                  min="0"
                  max="6"
                  step="1"
                  value={6 - localData.alertSeverity}
                  on:input={(e) => {
                    localData.alertSeverity = 6 - e.target.value; // Invertir valor al cambiar
                    validateForm();
                    updateFormOneData();
                  }}
                />
                <span class="ms-3 me-3">Máxima</span>
              </div>
              <div id="alertSeverityHelp" class="form-text">
                Selecciona la severidad de la alerta
              </div>
            </div>

            <div class="mb-3">
              <label for="alertFrequencyMax" class="form-label">Frecuencia máxima de alerta (min)</label>
              <input
                type="number"
                class="form-control"
                id="alertFrequencyMax"
                aria-describedby="alertFrequencyMaxHelp"
                bind:value={localData.alertFrequencyMax}
                on:input={() => {
                  validateForm();
                  updateFormOneData();
                }}
              />
              <div id="alertFrequencyMaxHelp" class="form-text">
                Frecuencia máxima con la que la alerta se genera en minutos, las alertas generadas en menor tiempo serán ignoradas.
              </div>
            </div>

            <div class="mb-3">
              <label for="alertFreqCounterMin" class="form-label">Tiempo mínimo para incrementar el contador de alertas (min)</label>
              <input
                type="number"
                class="form-control"
                id="alertFreqCounterMin"
                aria-describedby="alertFreqCounterMinHelp"
                bind:value={localData.alertFreqCounterMin}
                on:input={() => {
                  validateForm();
                  updateFormOneData();
                }}
              />
              <div id="alertFreqCounterMinHelp" class="form-text">
                Tiempo mínimo en minutos para incrementar el contador de alertas.
              </div>
            </div>

            <div class="mb-3">
              <label for="alertFreqDiscounterMin" class="form-label">Tiempo mínimo para regresar a cero el contador de alertas (min)</label>
              <input
                type="number"
                class="form-control"
                id="alertFreqDiscounterMin"
                aria-describedby="alertFreqDiscounterMinHelp"
                bind:value={localData.alertFreqDiscounterMin}
                on:input={() => {
                  validateForm();
                  updateFormOneData();
                }}
              />
              <div id="alertFreqDiscounterMinHelp" class="form-text">
                Tiempo mínimo en minutos después de que incrementó el contador de la alerta, para que regrese a cero el contador.
              </div>
            </div>

            <div class="mb-3">
              <label for="alertProtocol" class="form-label">Protocolo</label>
              <input
                type="number"
                class="form-control"
                id="alertProtocol"
                aria-describedby="alertProtocolHelp"
                bind:value={localData.alertProtocol}
                on:input={() => {
                  validateForm();
                  updateFormOneData();
                }}
              />
              <div id="alertProtocolHelp" class="form-text">
                Selecciona el protocolo de la alerta
              </div>
            </div>

            <!-- Checkbox para marcar alerta como activa -->
            <div class="mb-3 form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="alertActive"
                aria-describedby="alertActiveHelp"
                bind:checked={localData.alertActive}
                on:change={() => {
                  validateForm();
                  updateFormOneData();
                }}
              />
              <label class="form-check-label" for="alertActive"
                >Marcar alerta como activa</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </form>

</div>
